import { useEffect, useState } from 'react';
import './Menu.css';

export default function Menu(props) {

    let selected = props.category
    let setSelected = props.setCategory

    let updt
    let e

    useEffect(() => {
        let element = document.getElementById('menu__items')

        let cursorOnElement = false
        let updated = false

        document.documentElement.style.setProperty('--gradient', `linear-gradient(
            180deg,
            #15141A88 0%,
            rgba(0,0,0,0) ${selected*25}%,
            #15141ADD 100%
        )`)

        let interval

        let update = async (event) => {

            if(interval) clearInterval(interval)

            if(event) e = event
            let elementPositionY = element.getBoundingClientRect().top
            let elementPositionX = element.getBoundingClientRect().left
            let height = element.clientHeight
            let width = element.clientWidth

            var y = ((e.clientY || (e.touches ? e.touches[0].clientY : -1000)) - elementPositionY) / height
            var x = ((e.clientX || (e.touches ? e.touches[0].clientX : -1000)) - elementPositionX) / width

            if (x > 0 && x < 1 && y > -.5 && y < 1.5) {
                cursorOnElement = true
                updated = true
                document.documentElement.style.setProperty('--gradient', `linear-gradient(
                    180deg,
                    #15141A88 0%,
                    rgba(0,0,0,0) ${selected*25}%,
                    #15141A88 100%
                ), linear-gradient(
                    180deg,
                    #15141A66 0%,
                    rgba(0,0,0,0) ${y*100}%,
                    #15141A66 100%
                )`)
            } 
            else {
                if(!cursorOnElement) return
                cursorOnElement = false
                updated = false


                if(y < 0 || !y) y = 0
                if(y > 1) y = 1


                let dest = selected*25;
                let distance = y*100 - dest;
                let steps = Math.trunc(distance / 10)
                let step = distance / steps;

                if(steps < 0) {
                    steps = -steps
                    step = -step
                }

                if(steps > 0)
                for (let i = steps; i >= 0; i--) {
                    document.documentElement.style.setProperty('--gradient', `linear-gradient(
                        180deg,
                        #15141ABB 0%,
                        rgba(0,0,0,0) ${selected*25}%,
                        #15141ABB 100%
                    ), linear-gradient(
                        180deg,
                        #15141A66 0%,
                        rgba(0,0,0,0) ${dest + step*i}%,
                        #15141A66 100%
                    )`)
                    await new Promise(r => setTimeout(r, 20))
                    if(updated) return
                }

                document.documentElement.style.setProperty('--gradient', `linear-gradient(
                    180deg,
                    #15141ADD 0%,
                    rgba(0,0,0,0) ${selected*25}%,
                    #15141ADD 100%
                )`)
            }
          }

        updt = update
          

        document.onmousemove = update
        document.ontouchmove = update
    })

    return (
        <div className="portfolio-menu">
            <div className="portfolio-menu__title">Портфолио</div>
            <div className="portfolio-menu__items" id="menu__items">
                <div className="portfolio-menu__item" onClick={() => {
                    setSelected(0)
                }}>
                    <div className='portfolio-menu__text'>Графический дизайн</div> 
                </div>
                <div className="portfolio-menu__item" onClick={() => {
                    setSelected(1)
                }}>
                    <div className='portfolio-menu__text'>UI/UX дизайн</div>
                </div>
                <div className="portfolio-menu__item" onClick={() => {
                    setSelected(2)
                }}>
                    <div className='portfolio-menu__text'>Моушен дизайн</div>
                </div>
                <div className="portfolio-menu__item" onClick={() => {
                    setSelected(3)
                }}>
                    <div className='portfolio-menu__text'>3D дизайн</div>
                </div>
                <div className="portfolio-menu__item" onClick={() => {
                    setSelected(4)
                }}>
                    <div className='portfolio-menu__text'>Арты</div>
                </div>

            </div>
            <div className="portfolio-menu__line"></div>
            <div className="portfolio-menu__gradient"></div>
        </div>
    )
}