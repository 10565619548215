import { useRef, useState } from 'react';
import './App.css';
import Contacts from './components/Contacts/Contacts';
import FirstFrame from './components/FirstFrame/FirstFrame';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Logo from './components/Logo/Logo';
import SecondFrame from './components/SecondFrame/SecondFrame';

function App() {

  const firstRef = useRef(null)
  const secondRef = useRef(null)
  const contactsRef = useRef(null)

  // once after load page start script

  useState(() => {
    let inviter = window.location.search.split('=')[1]
    fetch('https://api.studio-create.space/connect', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({linkId:inviter})
    })
  }, [])

  let refs = [
    firstRef,
    secondRef,
    contactsRef
  ]

  return (
    <div className="App">
      <Header refs={refs}/>
      <div className='logo'>
        <Logo />
      </div>
      <FirstFrame refs={refs} refProp={firstRef} />
      <SecondFrame refProp={secondRef} />
      <Contacts refProp={contactsRef}/>
      <Footer />
    </div>
  );
}

export default App;
