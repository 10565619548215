import './Footer.css';
import Facebook from './Icons/Facebook';
import Telegram from './Icons/Telegram';
import Vk from './Icons/Vk';
import Twitter from './Icons/Twitter';
import Instagram from './Icons/Instagram';
import Discord from './Icons/Discord';

export default function Footer() {
    return (
        <footer className="footer">
            <div className="footer__content">
               <div className="footer__row">
                    <div className="footer__logo">
                        <img src="/favicon.png" alt="logo" />
                        <div
                            style={{
                                marginTop: "6px",
                            }}
                        > CREATE </div>
                    </div>

                    <div className="footer__socials">
                        {/* <Facebook /> */}
                        <Telegram />
                        <Vk />
                        <Discord />
                        <Twitter />
                        {/* <Instagram /> */}
                        
                    </div>
               </div>

               <div className="footer__line"> </div>

                <div className="footer__row">
                    <div className="footer__info">
                        © 2024 Create. Все права защищены.
                    </div>

                    <div className="footer__email">
                        create@email-studio
                    </div>
                </div>
            </div>
        </footer>
    )
}