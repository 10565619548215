import './Button.css';
import ButtonIcon from './ButtonIcon';

export default function Button({ text, onClick }) {
    return (
        <div className="button__container">
            <a href="https://t.me/Scarletflandree" target="_blank" rel="noreferrer">
            <button onClick={onClick} className="button_top"> <ButtonIcon /> {text}</button>
            </a>
        </div>
    );
}