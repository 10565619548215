import { useState } from 'react';
import Menu from './Menu/Menu';
import Portfolio from './Portfolio/Portfolio';
import './SecondFrame.css';
import Button from '../Button/Button';

export default function SecondFrame(props) {
    let [category, setCategory] = useState(0)

    return (
        <div ref={props.refProp} className="second-frame">
            <div className="second-frame__content">
                <Menu category={category} setCategory={setCategory} />
                <Portfolio category={category} />
            </div>
            <Button text="Портфолио" link="https://www.behance.net/ds_create" />
            {/* <div className="second-frame__text">
                <a href="https://www.behance.net/ds_create" target="_blank" rel="noreferrer">
                Здесь вы можете увидеть полное портфолио участников нашей студии.
                </a>
            </div> */}
        </div>
    )
}