export default function Logo() {
    return (
        <svg
        style={{
            marginTop: '5px',

        }}
        width="80" height="80" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M40.853 79.8018L35.7111 60.4071C35.4117 59.2779 34.8238 58.246 34.0052 57.4127L19.9438 43.0994C19.2196 42.3623 19.5608 41.1179 20.5596 40.853L39.9543 35.7111C41.0834 35.4117 42.1153 34.8239 42.9487 34.0052L57.262 19.9439C57.9991 19.2197 59.2435 19.5608 59.5083 20.5596L64.6503 39.9543C64.9496 41.0835 65.5375 42.1154 66.3562 42.9487L80.4175 57.262C81.1417 57.9992 80.8006 59.2436 79.8017 59.5084L60.4071 64.6503C59.2779 64.9497 58.246 65.5375 57.4127 66.3562L43.0994 80.4176C42.3622 81.1417 41.1178 80.8006 40.853 79.8018Z" stroke="white" stroke-width="5.38889"/>
        <path d="M55.9548 45.5487C62.318 53.2893 67.3964 60.8012 70.4705 66.6679C72.0134 69.6126 73.0127 72.0703 73.4409 73.8962C73.6558 74.8124 73.7061 75.4847 73.6576 75.9395C73.634 76.1601 73.5905 76.2973 73.5548 76.3754C73.5219 76.4472 73.4967 76.4681 73.4909 76.4728C73.4851 76.4776 73.4597 76.4983 73.3829 76.5166C73.2994 76.5366 73.1564 76.5528 72.9354 76.5332C72.4798 76.4928 71.8299 76.3134 70.9726 75.9253C69.2641 75.1519 67.0462 73.696 64.4557 71.6124C59.2946 67.4613 52.9072 61.0255 46.544 53.2849C40.1809 45.5443 35.1024 38.0324 32.0284 32.1657C30.4854 29.221 29.4861 26.7633 29.0579 24.9374C28.843 24.0212 28.7927 23.3489 28.8413 22.8941C28.8648 22.6735 28.9083 22.5363 28.9441 22.4582C28.9769 22.3864 29.0022 22.3655 29.0079 22.3608C29.0137 22.356 29.0391 22.3353 29.1159 22.317C29.1994 22.297 29.3425 22.2808 29.5634 22.3004C30.019 22.3408 30.6689 22.5202 31.5262 22.9083C33.2348 23.6817 35.4526 25.1376 38.0432 27.2212C43.2043 31.3723 49.5917 37.8082 55.9548 45.5487Z" stroke="url(#paint0_linear_206_60)" stroke-width="3.0456"/>
        <path opacity="0.2" d="M55.9548 45.5487C62.318 53.2893 67.3964 60.8012 70.4705 66.6679C72.0134 69.6126 73.0127 72.0703 73.4409 73.8962C73.6558 74.8124 73.7061 75.4847 73.6576 75.9395C73.634 76.1601 73.5905 76.2973 73.5548 76.3754C73.5219 76.4472 73.4967 76.4681 73.4909 76.4728C73.4851 76.4776 73.4597 76.4983 73.3829 76.5166C73.2994 76.5366 73.1564 76.5528 72.9354 76.5332C72.4798 76.4928 71.8299 76.3134 70.9726 75.9253C69.2641 75.1519 67.0462 73.696 64.4557 71.6124C59.2946 67.4613 52.9072 61.0255 46.544 53.2849C40.1809 45.5443 35.1024 38.0324 32.0284 32.1657C30.4854 29.221 29.4861 26.7633 29.0579 24.9374C28.843 24.0212 28.7927 23.3489 28.8413 22.8941C28.8648 22.6735 28.9083 22.5363 28.9441 22.4582C28.9769 22.3864 29.0022 22.3655 29.0079 22.3608C29.0137 22.356 29.0391 22.3353 29.1159 22.317C29.1994 22.297 29.3425 22.2808 29.5634 22.3004C30.019 22.3408 30.6689 22.5202 31.5262 22.9083C33.2348 23.6817 35.4526 25.1376 38.0432 27.2212C43.2043 31.3723 49.5917 37.8082 55.9548 45.5487Z" stroke="white" stroke-width="3.0456"/>
        <defs>
        <linearGradient id="paint0_linear_206_60" x1="48.0468" y1="49.852" x2="67.7677" y2="35.8901" gradientUnits="userSpaceOnUse">
        <stop stop-color="white"/>
        <stop offset="0.365" stop-color="white" stop-opacity="0"/>
        </linearGradient>
        </defs>
        </svg>
    );
}