import './Contacts.css';
import Member from './Member/Member';

export default function Contacts(props) {

    let members = [
        {
            name: "Shocon",
            position: ["ui/ux designer", "Graphic designer"],
            avatar: "./member1.png",
            link: "https://t.me/Scarletflandree"
        },
        {
            name: "thiro",
            position: ["Graphic designer"],
            avatar: "./member2.png",
            link: "https://t.me/dtisheko"
        },
        {
            name: "kristian",
            position: ["3D designer"],
            avatar: "./member3.png"
        },
        {
            name: "lutik",
            position: ["3D designer", "Motion designer", "Graphic designer"],
            avatar: "./member4.png",
            link: "https://t.me/LutikDesign"
        },
        {
            name: "yuma",
            position: ["Motion designer", "Graphic designer"],
            avatar: "./member5.png",
            link: "https://t.me/yumadesign"
        },
        {
            name: "whoooareyou",
            position: ["artist"],
            avatar: "./member6.png",
            link: "https://pin.it/1lWpn8ybj"
        },
        {
            name: "aqua",
            position: ["Frontend", "Backend", "Bot developer"],
            avatar: "./member7.png",
            link: "https://discord.gg/3UYSj2mFXu"
        },
        {
            name: "PhoenixSSL",
            position: ["Development Manager"],
            avatar: "./member8.png",
            link: "https://t.me/createmanager"
        },
        {
            name: "INVST.",
            position: ["Graphic designer"],
            avatar: "./member9.png",
            link: "https://t.me/theinvst"
        }
    ]

    return (
        <div ref={props.refProp} className="contacts">
            <div className="contacts__title">
                Контакты
            </div>
            <div className="contacts__members">
                {members.map((member, index) => (
                    <Member key={index} name={member.name} position={member.position} avatar={member.avatar} link={member.link} />
                ))}
            </div>
        </div>
    )
}