import Button from '../Button/Button';
import './FirstFrame.css';
import SocialLinks from './SocialLinks/SocialLinks';

export default function FirstFrame(props) {
    return (
        <div ref={props.refProp} className="first-frame">
            <div className="first-frame__content">
                <h1 className="first-frame__title">Создайте свой собственный стиль 
и качество.</h1>
                <p className="first-frame__text">2D 3D Статический Динамический</p>
                <Button text="Заказать" />
            </div>
            <SocialLinks refs={props.refs} />
            <div className="first-frame__light"></div>
            <div className="first-frame__lines">
                <svg viewBox="0 0 1920 508" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.54" d="M957.304 414.351C1507.86 571.26 1922.28 495.415 2060.67 437.878L2030.94 97.4837C1830.89 180.535 1460.89 316.274 1228.27 215.903C787.874 25.8785 305.908 28.6144 -54.0183 0.338121L-95 398.28C-95 398.28 269.104 218.214 957.304 414.351Z" fill="url(#paint0_linear_0_234)"/>
                    <defs>
                    <linearGradient id="paint0_linear_0_234" x1="2068.57" y1="361.226" x2="-68.5187" y2="141.14" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#090D15"/>
                    <stop offset="0.848025" stop-color="#0A0F18" stop-opacity="0"/>
                    </linearGradient>
                    </defs>
                </svg>
            </div>
        </div>
    )
}