export default function Discord() {
    return (
        <a href="https://t.me/dsgcreate" target="_blank" rel="noreferrer" 
        style={{marginTop: '-10px;'}}
        >
            <svg width="56" height="56" viewBox="0 -5 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M40.439 17.5589C40.6532 16.1734 39.3378 15.0799 38.1078 15.6208L13.6085 26.3929C12.7264 26.7807 12.7909 28.1187 13.7058 28.4105L18.7582 30.0218C19.7224 30.3293 20.7666 30.1703 21.6086 29.5877L32.9995 21.7066C33.343 21.4689 33.7174 21.958 33.4239 22.2611L25.2245 30.7269C24.4292 31.5482 24.587 32.9397 25.5437 33.5406L34.7238 39.3057C35.7535 39.9523 37.078 39.3027 37.2706 38.0567L40.439 17.5589Z"/>
            </svg>
        </a>
    )
}