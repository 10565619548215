import PortfolioItem from "./PortfolioItem/PortfolioItem";
import "./Portfolio.css";

export default function Portfolio(props) {

    console.log()

    let category = props.category

    let items = [
        [
            {
                img: './graphic/1.png',
                link: 'https://ru.pinterest.com/swwjseei/'
            },
            {
                img: './graphic/2.png',
                link: 'https://www.behance.net/gallery/191014211/Logotype-tournament-design'
            },
            {
                img: './graphic/5.png',
                link: 'https://www.behance.net/gallery/184765851/Discord-Design-Mirwana'
            },

            {
                img: './graphic/4.png',
                link: 'https://www.behance.net/gallery/190528567/Discord-design-darkness'
            },
            {
                img: './graphic/3.png',
                link: 'https://www.behance.net/gallery/191829321/Discord-server-design-crystalys'
            },
            {
                img: './graphic/6.png',
                link: 'https://www.behance.net/gallery/188809297/Discord-server-Silent-Night'
            }
        ],
        [
            {
                img: './uiux/1.png',
                link: 'https://www.behance.net/gallery/190438751/Telegram-app-design'
            },
            {
                img: './uiux/2.png',
                link: 'https://www.behance.net/gallery/194074103/HASHPOWER-AI-website'
            },
            {
                img: './uiux/3.png',
                link: 'https://www.behance.net/gallery/190799483/INNOVABANK-(mobile-banking-app)'
            }
        ],
        [
            {
                img: './motion/1.png',
                link: 'https://t.me/yumadesign/129'
            },
            {
                img: './motion/2.png',
                link: 'https://t.me/LutikDesign/43'
            },
            {
                img: './motion/3.png',
                link: 'https://t.me/yumadesign/102'
            },
            {
                img: './motion/4.png',
                link: 'https://t.me/yumadesign/133'
            },
            {
                img: './motion/5.png',
                link: 'https://t.me/yumadesign/137'
            },
            {
                img: './motion/6.png',
                link: 'https://t.me/yumadesign/135'
            }
        ],
        [
            {
                img: './3d/1.png',
                link: 'https://www.behance.net/gallery/178629557/Discord-server-design-1'
            },
            {
                img: './3d/2.png',
                link: 'https://www.behance.net/gallery/190528567/Discord-design-darkness'
            },
            {
                img: './3d/3.png',
                link: 'https://www.behance.net/gallery/182663013/Bledner-product-mockup',
            },
            {
                img: './3d/4.png',
                link: 'https://www.behance.net/gallery/182999559/French-whiskey-concept',
            }
        ],
        [
            {
                img: './arts/1.png',
                link: 'https://ru.pinterest.com/pin/616571005268068617/'
            },
            {
                img: './arts/2.png',
                link: 'https://ru.pinterest.com/pin/616571005268068606/'
            },
            {
                img: './arts/3.png',
                link: 'https://ru.pinterest.com/pin/616571005268068660/'
            },
            {
                img: './arts/4.png',
                link: 'https://ru.pinterest.com/pin/616571005268068542/'
            },
            {
                img: './arts/5.png',
                link: 'https://ru.pinterest.com/pin/616571005266774928/'
            },
            {
                img: './arts/6.png',
                link: 'https://ru.pinterest.com/pin/616571005268068608/'
            }
        ]
    ]

    return (
        <div className="portfolio">

            {
                items.map((item, index) => (
                    <div className="portfolio__items" style={{
                        opacity: category === index ? 1 : 0,
                        position: category === index ? 'relative' : 'absolute',
                        zIndex: category === index ? 1 : -1
                    }}>
                        {item.map((item, index) => (
                            <PortfolioItem key={index} img={item.img} link={item.link} />
                        ))}
                    </div>
                ))
            }



        </div>
    )
}