export default function Discord(props) {

    return (
        <div className="discord">
            <a href="https://discord.gg/zxcloli" target="_blank" rel="noreferrer">
            <svg width="56" height="56" viewBox="-1 -1 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M41.3976 16.0642C38.9516 14.9875 36.3363 14.2049 33.6022 13.7594C33.2663 14.3306 32.8741 15.0988 32.6037 15.71C29.6971 15.2988 26.8172 15.2988 23.9642 15.71C23.6938 15.0988 23.2925 14.3306 22.9538 13.7594C20.2166 14.2049 17.5984 14.9903 15.1525 16.0699C10.2191 23.0841 8.88169 29.9242 9.55038 36.667C12.8225 38.9661 15.9935 40.3626 19.1111 41.2765C19.8808 40.2798 20.5673 39.2203 21.1587 38.1036C20.0324 37.7009 18.9536 37.204 17.9342 36.627C18.2046 36.4386 18.4691 36.2415 18.7247 36.0388C24.9421 38.7747 31.6972 38.7747 37.8402 36.0388C38.0987 36.2415 38.3633 36.4386 38.6307 36.627C37.6084 37.2068 36.5266 37.7037 35.4002 38.1065C35.9917 39.2203 36.6752 40.2827 37.4479 41.2794C40.5684 40.3655 43.7424 38.969 47.0145 36.667C47.7992 28.8503 45.6742 22.0731 41.3976 16.0642ZM22.0057 32.5202C20.1394 32.5202 18.6088 30.8808 18.6088 28.8846C18.6088 26.8883 20.1067 25.2461 22.0057 25.2461C23.9048 25.2461 25.4353 26.8854 25.4026 28.8846C25.4057 30.8808 23.9048 32.5202 22.0057 32.5202ZM34.5592 32.5202C32.6928 32.5202 31.1622 30.8808 31.1622 28.8846C31.1622 26.8883 32.6601 25.2461 34.5592 25.2461C36.4582 25.2461 37.9888 26.8854 37.9561 28.8846C37.9561 30.8808 36.4582 32.5202 34.5592 32.5202Z"/>
            </svg>
            </a>
        </div>
    )
}