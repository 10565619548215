import Discord from './Icons/Discord';
import Telegram from './Icons/Telegram';
import Twitter from './Icons/Twitter';
import VK from './Icons/VK';
import './SocialLinks.css';

export default function SocialLinks(props) {
    return (
        <div className="social-links">
            <div className="line"></div>
            <div className="social-links__icons">
                <Discord refs={props.refs}/>
                <Telegram />
                {/* <VK /> */}
                <Twitter />
            </div>
        </div>
    )
}