import './Member.css'

export default function Member(props) {
    return (
        <a href={props.link} target="_blank" rel="noreferrer"
            style={{
                textDecoration: 'none',
                color: 'inherit'
            }}
        >
            <div className="member">
                {avatar(props.avatar)}
                {name(props.name)}
                {props.position.map((pos, index) => position(pos, index))}
            </div>
        </a>
    )
}

function avatar(url) {
    return (
        <div className="member__avatar">
            <img src={url} alt="avatar" />
        </div>
    )
}

function name(name) {
    return (
        <div className="member__name">
            {name}
        </div>
    )
}

function position(position) {
    return (
        <div className="member__position">
            {position}
        </div>
    )
}