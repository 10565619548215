import "./PortfolioItem.css";

export default function PortfolioItem({ img, link }) {
    return (
        <a href={link} target="_blank" rel="noreferrer">
            <div className="portfolio-item">
                <img src={img} alt="portfolio" className="portfolio-item__img" />
            </div>
        </a>
    )
}