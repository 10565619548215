import './Button.css';
import ButtonIcon from './ButtonIcon';

export default function Button({ text, onClick, link }) {
    let url = link || "https://t.me/Scarletflandree";
    return (
        <div className="button__container">
            <a href={url} target="_blank" rel="noreferrer">
            <button onClick={onClick} className="button"> <ButtonIcon /> {text}</button>
            </a>
        </div>
    );
}