import { useEffect, useState } from 'react';
import ButtonTop from '../Button/ButtonTop';
import './Header.css';

function Header(props) {

    let [activeLink, setActiveLink] = useState(0);

    let refs = props.refs;

    useEffect(() => {
        window.addEventListener('scroll', () => {
            let scroll = window.scrollY;
            let index = 0;
            for (let i = 0; i < refs.length; i++) {
                if (refs[i].current.offsetTop - 500 <= scroll) {
                    index = i;
                }
            }
            setActiveLink(index);
        })
    });

    let links = [
        {name: 'Главная'},
        {name: 'Портфолио'},
      //  {name: 'Услуги'},
        {name: 'Контакты'},
      //  {name: 'Футер'}
    ]

    function LinkScroll(text, positionY, active, index) {
        return (
            <div className="link__container">
                <a
                    onClick={() => {
                        if(index == 1){
                            let positionY = refs[index].current.offsetTop;
                            positionY -= 100;
                            window.scrollTo({
                                top: positionY,
                                behavior: 'smooth'
                            });
                        } else 
                            refs[index].current.scrollIntoView({ behavior: 'smooth', block: 'start' });
                        //setActiveLink(links.findIndex(link => link.positionY === positionY));
                    }}
                    className={active ? 'link link_active' : 'link'}
                >{text}</a>
                <div className={active ? 'link__line' : 'unactive_line'}></div>
            </div>
        );
    }

  return (
    <header className="App-header">
        <div className="links">
{/* 
    Главная Портфолио Услуги Контакты Футер
*/}
            {links.map((link, index) => LinkScroll(link.name, link.positionY, index === activeLink, index))}
        </div>

        <div style={{
            marginRight: "20px",
            marginTop: "-15px"
        }}>
            <ButtonTop text="Заказать" />
        </div>
        
    </header>
  );
}

export default Header;
